<template>
  <div>
    <b-card style="max-width: 50rem" class="mb-2">
      <span class="error"
        ><small v-if="showError">{{ errMsg }}</small></span
      >
      <span class="success"
        ><small v-if="showSucc">{{ succMsg }}</small></span
      >
      <b-form id="form-c" @submit="onSubmit">
        <b-row cols="1" cols-sm="2" cols-md="2" cols-lg="4">
          <b-col id="inp-col">
            <b-form-input
              class="inputs"
              maxlength="100"
              placeholder="Name*"
              v-model="form.name"
              pattern="[a-zA-Z0-9-åÅæÆøØ-آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهی ]+"
              required
            ></b-form-input>
          </b-col>
          <b-col id="inp-col">
            <b-form-input
              class="inputs"
              type="email"
              maxlength="150"
              placeholder="Email*"
              v-model="form.email"
              required
            ></b-form-input>
          </b-col>
          <b-col id="btn-col">
            <button
              id="btn"
              type="submit"
              class="submit"
              :disabled="activeButton"
              size="md"
            >
              Subscribe
            </button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { HTTP } from "../../HTTP";

export default {
  name: "comment",
  props: {
    blogId: String,
  },
  created() {
    if (localStorage.getItem("cookie:accepted")) {
      const register = this.$cookie.get("register");
      if (register != null) {
        const decryptedRegister = this.$CryptoJS.AES.decrypt(
          register,
          this.$skDecEnc
        ).toString(this.CryptoJS.enc.Utf8);
        const regData = JSON.parse(decryptedRegister);
        this.form.name = regData.name;
        this.form.email = regData.email;
      }
    }
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        comment: "subscription",
        language: "en",
      },
      activeButton: false,
      errMsg: "",
      showError: true,
      succMsg: "",
      showSucc: false,
    };
  },
  methods: {
    validateLength(form) {
      if (form.name.length > 100 || form.email.length > 150) {
        this.showSucc = false;
        this.errMsg = "Invalid name or email address!";
        this.showError = true;
        return false;
      }
      this.showError = false;
      return true;
    },
    validateEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        this.showSucc = false;
        this.errMsg = "Invalid name or email address!";
        this.showError = true;
        return false;
      }
      return true;
    },
    onSubmit(event) {
      if (
        this.validateLength(this.form) &&
        this.validateEmail(this.form.email)
      ) {
        if (localStorage.getItem("cookie:accepted")) {
          var date = new Date();
          date.setDate(date.getDate() + 30);
          const register = {
            name: this.form.name,
            email: this.form.email,
          };
          const encryptedText = this.$CryptoJS.AES.encrypt(
            JSON.stringify(register),
            this.$skDecEnc
          ).toString();
          this.$cookie.set("register", encryptedText, date);
        }
        HTTP.post("PutUser/", this.form, {
          headers: {
            "Content-Type": "text/plain;charset=UTF-8",
          },
        }).catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
        this.succMsg = "Thank you for registration!";
        this.showSucc = true;
        this.activeButton = true;
      }
      event.preventDefault();
    },
  },
};
</script>
<style scoped>
.card {
  margin: auto;
  border: 1px solid #376996;
  box-shadow: 1px 1px 3px 0px #376996;
}
.error {
  font-size: 0.9rem;
  color: #a10702;
  float: left;
}
.success {
  font-size: 0.9rem;
  color: #376996;
  float: left;
}

@media screen and (max-width: 600px) {
  #btn-col {
    margin-top: 2%;
  }
}
</style>
