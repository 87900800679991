<template>
  <div id="blogComp" cols="12" sm="4" md="4" lg="4" class="w-90 p-3 mb-1">
    <div
      v-if="loading"
      class="d-flex justify-content-center mb-3"
      style="padding-top: 500px; padding-bottom: 500px"
    >
      <b-spinner label=""></b-spinner>
      <strong>&nbsp;&nbsp;&nbsp;Loading...</strong>
    </div>
    <div v-else>
      <div id="ads"></div>
      <div class="blogDiv" @scroll.passive="handleScroll">
        <div class="top-blog">
          <h1>
            <strong>{{ currentBlog.title }}</strong>
          </h1>
          <span>{{ currentBlog.pubdate.substring(0, 10) }}</span>
          <span>By {{ currentBlog.author }},</span>
          <span>{{ currentBlog.readtime }} min read.</span>
          <b-img
            :src="getImgUrl()"
            center
            rounded
            class="m1"
            :alt="currentBlog.title"
            id="myBcg"
          ></b-img>
          <span>Photo: HanifTadjik.com</span>
        </div>
        <div class="share-sm">
          <ShareSmall
            v-bind:blogId="currentBlog.id"
            :blogTitle="currentBlog.title"
            :blogDesc="currentBlog.description"
          />
        </div>
        <h2 class="lh-lm text-md-start desc">{{ currentBlog.description }}</h2>
        <div
          v-html="currentBlog.content"
          class="lh-lg text-md-start content"
        ></div>
      </div>
      <div id="ads"></div>
      <Tags v-bind:blogTags="currentBlog.tags" />
      <div id="ads"></div>
      <!-- <Comment v-bind:blogId="currentBlog.id" />  -->
      <Register></Register>
      <Share
        v-bind:blogId="currentBlog.id"
        :blogTitle="currentBlog.title"
        :blogDesc="currentBlog.description"
      />
      <!-- Other similar posts - bring similat posts and show the same as someblogs post-->
      <div v-if="currentBlog.category">
        <SimilarBlogs :blogCategory="currentBlog.category" />
      </div>
    </div>
    <button id="top" style="visibility: hidden" @click="toTop()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-chevron-double-up"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"
        />
        <path
          fill-rule="evenodd"
          d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>
    </button>
  </div>
</template>
<script>
import { HTTP } from "../HTTP";
import Tags from "./helpers/Tags.vue";
import Register from "./helpers/Register.vue";
import Share from "./helpers/Share.vue";
import SimilarBlogs from "./helpers/SimilarBlogs.vue";
import ShareSmall from "./helpers/ShareSmall.vue";
export default {
  name: "blog",
  components: {
    Tags,
    Register,
    Share,
    SimilarBlogs,
    ShareSmall,
  },
  props: {
    blogId: String,
  },
  data() {
    return {
      currentBlog: {
        id: null,
        pubdate: "",
        category: "",
        author: "",
        readtime: "",
        title: "",
        description: "",
        content: "",
        imagepath: "logo",
        tags: "",
      },
      loading: true,
      scTimer: 0,
      scY: 0,
    };
  },
  async created() {
    window.addEventListener("scroll", this.handleScroll, false);
    const idEnc = localStorage.getItem("id");
    let id = this.$route.query.id;
    if (idEnc == null && id == null) {
      this.$router
        .push({
          name: "NotFound",
        })
        .catch(() => {});
      this.loading = false;
      return;
    }

    if (idEnc) {
      const decryptedId = this.$CryptoJS.AES.decrypt(
        idEnc,
        this.$skDecEnc
      ).toString(this.CryptoJS.enc.Utf8);
      await HTTP.get("GetBlog/" + decryptedId).then(
        (response) => (this.currentBlog = response.data.data)
      );
    }
    if (id) {
      await HTTP.get("GetBlog/" + id).then(
        (response) => (this.currentBlog = response.data.data)
      );
    }
    //this.currentBlog.content = await this.firstLetter(this.currentBlog.content);
    this.loading = false;
  },
  methods: {
    getImgUrl() {
      var images = require.context("../assets/figure", false, /\.png$/);
      return images("./" + this.currentBlog.imagepath + ".png");
    },
    async firstLetter(content) {
      let newContent = "";
      main: for (var i = 0; i < content.length; i++) {
        if (
          content[i] === "<" &&
          content[i + 1] === "p" &&
          content[i + 2] === ">"
        ) {
          newContent = await content.replace(
            content[i + 3],
            '<span class="letter" style="font-size: 350%; color:#1f487e; float:left; margin-right:1.2%;font-weight:bold;line-height:1;">' +
              content[i + 3] +
              "</span>"
          );
          break main;
        }
      }
      return newContent;
    },
    handleScroll() {
      if (this.scTimer) return;
      const scrollBtn = document.querySelector("#top");
      if (window.scrollY > 400) {
        if (scrollBtn === null) {
          window.removeEventListener("scroll", this.handleScroll, false);
          return;
        }
        scrollBtn.style.visibility = "visible";
      } else {
        if (scrollBtn === null) {
          window.removeEventListener("scroll", this.handleScroll, false);
          return;
        }
        scrollBtn.style.visibility = "hidden";
      }
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async openBlogOnAir() {
      // get id from request query
      const id = this.$route.query.id;
      console.log("opening blog..", this.$route.query.id);
      await HTTP.get("GetBlog/" + id).then((response) => console.log(response));
      if (this.currentBlog === null) {
        this.$router
          .push({
            name: "NotFound",
          })
          .catch(() => {});
        return;
        // get blog from db , RESOLVED
      }
    },
  },
};
</script>

<style scoped>
/*h1,
h2 {
  padding-top: 50px;
  padding-bottom: 20px;
  font-weight: 450;
  color: #1d3461;
}
p {
  padding-top: 50px;
  padding-bottom: 70px;
}
*/

#blogComp {
  margin: auto;
  background-color: rgb(31, 72, 126, 0.05);
}
.blogDiv {
  margin: auto;
  padding-bottom: 20px;
  width: 65%;
  text-align: left;
}
.top-blog {
  text-align: center;
}

#ads {
  height: 150px !important;
}
span {
  color: #87aaaa;
  padding: 5px;
  text-align: left;
}

#myBcg {
  padding-top: 5px;
  width: 45%;
  height: 45%;
  margin: auto;
  height: auto;
}
.share-sm {
  max-width: fit-content;
  max-height: min-content;
  float: right;
  margin-right: 10%;
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .blogDiv {
    width: 92%;
  }
  #myBcg {
    width: 90%;
    height: 90%;
  }
  .top-blog {
    font-size: x-small;
  }
  .share-sm {
    white-space: nowrap;
    float: none;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  h2 {
    padding-top: 20px;
  }
}

.sharing {
  padding-top: 20px;
}
.content {
  color: #1f487e;
}
#top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 100;
  opacity: 0.6;
  background-color: #faa613;
  border: 0.05px solid #f44708;
  box-shadow: 0.5px 0.5px 1px #faa613, -0.5px -0.5px 1px #ffffff;
}
#top:hover {
  background-color: #f44708;
}
</style>
