<template>
  <div class="bloglists">
    <hr class="hr1" />
    <h2 style="font-weight: bold; padding-bottom: 10px; color: #1f487e">
      Similar posts
    </h2>
    <b-container fluid>
      <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="3">
        <!--  -->
        <b-col v-for="(blog, index) in blogsPerPage" :key="blog.id">
          <b-card
            :header="blog.title"
            class="text-left"
            @mouseover="hoverCard(index)"
            @mouseout="hoverCard(-1)"
            @click="openBlog(blog)"
          >
            <b-card-text
              >{{ blog.description }}..<br />
              <div>
                <span
                  class="category"
                  @click.stop.prevent="openCategory(blog.category)"
                >
                  <a
                    ><small>{{ blog.category }}</small></a
                  >
                </span>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <!--  -->
      </b-row>
    </b-container>
    <div>
      <button
        id="btn"
        type="button"
        class="submit"
        @click="openCategory(blogCategory)"
        size="md"
      >
        Read more
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-double-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
          />
          <path
            fill-rule="evenodd"
            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../HTTP";
import moment from "moment";
import { ToBlog } from "./utils.js";

export default {
  name: "similarblogs",
  components: {},
  props: {
    blogCategory: String,
  },
  data() {
    return {
      perPage: 3,
      currentPage: 1,
      blogs: [],
      selectedCard: -1,
      activePage: 1,
    };
  },
  computed: {
    rows() {
      return this.blogs.length;
    },
    blogsPerPage() {
      return this.blogs.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    formattedBlogs() {
      return this.blogs.reduce((b, n, i) => {
        if (i % 4 === 0) b.push([]);
        b[b.length - 1].push(n);
        return b;
      }, []);
    },
  },

  async created() {
    const cat = this.blogCategory;
    await HTTP.get("GetSimilarBlogs/en/" + cat).then(
      (response) => (this.blogs = response.data.data)
    );
    this.blogs.forEach((blog) => {
      blog.title = blog.title.slice(0, 20) + "..";
    });
    this.blogs.forEach((blog) => {
      blog.description = blog.description.slice(0, 30);
    });
    var d = this.dateThreshold(new Date(), 3);
    var dateThresh = moment(new Date(d.toDateString())).format("YYYY-MM-DD");
    this.blogs.filter((blog) => {
      return dateThresh > moment(new Date(blog.pubdate)).format("YYYY-MM-DD");
    });
    if (this.blogs.length > 6) {
      this.blogs = this.blogs.slice(0, 5);
    }
  },
  methods: {
    dateThreshold(d, nrOfMonth) {
      const month = d.getMonth();
      d.setMonth(d.getMonth() - nrOfMonth);
      while (month === d.getMonth()) {
        d.setDate(d.getDate() - nrOfMonth);
      }
      return d;
    },
    getLength() {
      return this.blogs.length;
    },
    changePage(currentPage) {
      this.activePage = currentPage;
      this.currentPage = currentPage;
    },
    openBlog(blog) {
      ToBlog(blog.id, blog.title);
    },
    openCategory(category) {
      localStorage.setItem("category", category);
      this.$router
        .push({
          name: "Category",
        })
        .catch(() => {});
    },
    hoverCard(selectedIndex) {
      this.selectedCard = selectedIndex;
    },
    isSelected(blogIndex) {
      return this.selectedCard === blogIndex;
    },
  },
};
</script>

<style lang="css" scoped>
.bloglists {
  max-width: 95%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.hr1 {
  margin: auto;
  max-width: 85%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #6290c8, #829cbc, #6290c8);
}
.card {
  padding: 2%;
  text-align: left;
  margin: 2%;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px #376996;
  /* transition: height 1.5s, box-shadow 1.5s; */
}

.card-header {
  font-weight: bold;
  background-color: #ffff;
  border-bottom: 1px solid #6290c8;
}
.card:hover {
  box-shadow: 2px 6px 10px 0px #376996;
}
#btn {
  margin-top: 2%;
}
</style>
