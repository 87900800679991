<template>
  <div>
    <hr class="hr" />
    <h2 style="font-weight: bold; padding-bottom: 10px; color: #1f487e">
      Share this blog
    </h2>
    <ShareNetwork
      v-for="network in networks"
      :network="network.network"
      :key="network.network"
      :url="sharing.url"
      :title="sharing.title"
      :description="sharing.description"
      :quote="sharing.quote"
      :hashtags="sharing.hashtags"
      :twitterUser="sharing.twitterUser"
    >
      <!-- class="submit"
        :style="{ backgroundColor: network.color }" -->
      <span
        :style="{ color: network.color, marginRight: '1%' }"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        :title="network.tooltip"
      >
        <font-awesome-icon :icon="['fab', network.icon]" size="2x" />
        <span>{{ network.name }}</span>
      </span>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: "share",
  props: {
    blogId: Number,
    blogTitle: String,
    blogDesc: String,
  },
  created() {},
  data() {
    return {
      sharing: {
        url: "https://hanifblogs.azurewebsites.net/blog/" + this.blogId,
        title: this.blogTitle,
        description: this.blogDesc,
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "vuejs,vite,javascript",
        twitterUser: "youyuxi",
      },
      networks: [
        {
          network: "facebook",
          name: "",
          tooltip: "Share on Facebook",
          icon: "facebook",
          color: "#376996",
        },
        {
          network: "linkedin",
          name: "",
          tooltip: "Share on LinkedIn",
          icon: "linkedin",
          color: "#1f487e",
        },
        {
          network: "reddit",
          name: "",
          tooltip: "Share on Reddit",
          icon: "reddit",
          color: "#a10702",
        },
        {
          network: "telegram",
          name: "",
          tooltip: "Share on Telegram",
          icon: "telegram",
          color: "#376996",
        },
        {
          network: "twitter",
          name: "",
          tooltip: "Share on Twitter",
          icon: "twitter",
          color: "#6290c8",
        },
      ],
    };
  },
};
</script>

<style scoped>
div {
  padding-top: 50px;
  padding-bottom: 50px;
}
a {
  text-decoration: none;
}
.hr {
  margin: auto;
  max-width: 80%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #6290c8, #829cbc, #6290c8);
  margin-bottom: 5px;
}
h4 {
  padding-top: 10px;
}
.submit {
  margin: 1%;
  display: inline-block;
}
</style>
