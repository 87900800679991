<template>
  <div class="small-share">
    <ShareNetwork
      v-for="network in networks"
      :network="network.network"
      :key="network.network"
      :url="sharing.url"
      :title="sharing.title"
      :description="sharing.description"
      :quote="sharing.quote"
      :hashtags="sharing.hashtags"
      :twitterUser="sharing.twitterUser"
    >
      <font-awesome-icon size="lg" :icon="['fab', network.icon]" />
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: "share",
  props: {
    blogId: Number,
    blogTitle: String,
    blogDesc: String,
  },
  created() {},
  data() {
    return {
      sharing: {
        url: "https://hanifblogs.azurewebsites.net/blog/" + this.blogId,
        title: this.blogTitle,
        description: this.blogDesc,
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "vuejs,vite,javascript",
        twitterUser: "youyuxi",
      },
      networks: [
        {
          network: "facebook",
          name: " Facebook",
          tooltip: "Share on Facebook",
          icon: "facebook",
          color: "#376996",
        },
        {
          network: "linkedin",
          name: " LinkedIn",
          tooltip: "Share on LinkedIn",
          icon: "linkedin",
          color: "#1f487e",
        },
        {
          network: "reddit",
          name: " Reddit",
          tooltip: "Share on Reddit",
          icon: "reddit",
          color: "#a10702",
        },
        {
          network: "telegram",
          name: " Telegram",
          tooltip: "Share on Telegram",
          icon: "telegram",
          color: "#376996",
        },
        {
          network: "twitter",
          name: " Twitter",
          tooltip: "Share on Twitter",
          icon: "twitter",
          color: "#6290c8",
        },
      ],
    };
  },
};
</script>

<style scoped>
div.small-share {
  padding: 5px 5px 5px 5px;
  max-width: 100%;
  display: inline-block;
}
a {
  text-decoration: none;
  margin-right: 10%;
  color: #faa613;
}

a:hover {
  color: #f44708;
}
</style>
